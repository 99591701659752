import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import ReactHookFormSelect from 'src/components/common/ReactHookFormSelect';
import { useCreateOperator } from 'src/services/operator/hooks';
import { useAgencies } from 'src/services/agency/hooks';

import { validCommissions } from 'src/services/operator/config';

import { renderMenuItems } from '../helpers';
import { validationSchema, defaultValues, NO_AGENCY_VALUE } from './schema';
import * as S from './styled';
import { languageToCityMap, operatorLanguages } from './config';

const fullWidthStyle = { width: '100%' };

export const CreateOperatorForm = () => {
  const createOperator = useCreateOperator();
  const { value: agencies, loading: agenciesLoading } = useAgencies();

  const { control, handleSubmit, errors, formState, reset, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    const success = await createOperator({
      ...values,
      city: languageToCityMap[values.language],
      agencyTechName: values.agencyTechName !== NO_AGENCY_VALUE ? values.agencyTechName : undefined,
    });
    if (success) reset();
  });

  const isFreeMember = watch('isFreeMember');

  const commissionItems = useMemo(() => renderMenuItems(validCommissions), []);
  const languageItems = useMemo(() => renderMenuItems(operatorLanguages, (lang) => lang.toUpperCase()), []);
  const booleanSelectItems = useMemo(
    () =>
      [false, true].map((value) => (
        <MenuItem
          key={value.toString()}
          // @ts-expect-error should accept boolean value normally
          value={value}
        >
          {value ? 'Yes' : 'No'}
        </MenuItem>
      )),
    [],
  );
  const agenciesItems = useMemo(
    () =>
      agencies?.map((agency) => (
        <MenuItem key={agency.techName} value={agency.techName}>
          {agency.name}
        </MenuItem>
      )),
    [agencies],
  );

  return (
    <S.Form onSubmit={handleFormSubmit}>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="firstName"
          type="string"
          control={control}
          label="Operator first name"
          error={!!errors.firstName}
          variant="outlined"
          size="small"
          style={fullWidthStyle}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="lastName"
          type="string"
          control={control}
          label="Operator last name"
          error={!!errors.lastName}
          variant="outlined"
          size="small"
          style={fullWidthStyle}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <ReactHookFormSelect
          size="small"
          error={!!errors.commission}
          name="commission"
          label="Operator commission"
          control={control}
          variant="outlined"
        >
          {commissionItems}
        </ReactHookFormSelect>
      </S.InputWrapper>
      {!isFreeMember && (
        <S.InputWrapper>
          <ReactHookFormSelect
            disabled={agenciesLoading}
            size="small"
            error={!!errors.agencyTechName}
            name="agencyTechName"
            label="Agency"
            control={control}
            variant="outlined"
          >
            <MenuItem key={NO_AGENCY_VALUE} value={NO_AGENCY_VALUE}>
              Without Agency
            </MenuItem>
            {agenciesItems}
          </ReactHookFormSelect>
        </S.InputWrapper>
      )}
      <S.InputWrapper>
        <ReactHookFormSelect
          error={!!errors.isReadyForExplicit}
          size="small"
          name="isReadyForExplicit"
          label="Explicit chat"
          control={control}
          variant="outlined"
        >
          {booleanSelectItems}
        </ReactHookFormSelect>
      </S.InputWrapper>
      <S.InputWrapper>
        <ReactHookFormSelect
          error={!!errors.isFreeMember}
          size="small"
          name="isFreeMember"
          label="Free member"
          control={control}
          variant="outlined"
        >
          {booleanSelectItems}
        </ReactHookFormSelect>
      </S.InputWrapper>

      {isFreeMember ? (
        <>
          <S.InputWrapper>
            <Controller
              as={TextField}
              name="freeMemberAnimatorClientId"
              type="string"
              control={control}
              label="Animator id (set id)"
              error={!!errors.freeMemberAnimatorClientId}
              helperText={errors.freeMemberAnimatorClientId?.message}
              variant="outlined"
              size="small"
              style={fullWidthStyle}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Controller
              as={TextField}
              name="animatorCity"
              type="string"
              control={control}
              label="Animator city"
              error={!!errors.animatorCity}
              helperText={errors.animatorCity?.message}
              variant="outlined"
              size="small"
              style={fullWidthStyle}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Controller
              as={TextField}
              name="animatorCountry"
              type="string"
              control={control}
              label="Animator country (abbreviation)"
              error={!!errors.animatorCountry}
              helperText={errors.animatorCountry?.message}
              variant="outlined"
              size="small"
              style={fullWidthStyle}
            />
          </S.InputWrapper>
        </>
      ) : (
        <S.InputWrapper>
          <ReactHookFormSelect
            error={!!errors.language}
            size="small"
            name="language"
            label="Language"
            control={control}
            variant="outlined"
          >
            {languageItems}
          </ReactHookFormSelect>
        </S.InputWrapper>
      )}

      <Button type="submit" variant="contained" color="primary" disabled={formState.isSubmitting}>
        Create Operator
      </Button>
    </S.Form>
  );
};

export enum FormType {
  Operator = 'operator',
  // Agency = 'agency',
}

export enum FormAction {
  Create = 'create',
  // Deactivate = 'deactivate',
  // Reactivate = 'reactivate',
  // ChangeCommission = 'changeCommission',
}

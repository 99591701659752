import { Http } from 'src/network/http';

import { ActivateOperatorPayload, OperatorsDto } from './types';
import { adapter } from './adapter';

const getAll = (realm: string) => {
  return Http.shared()
    .instance.get<OperatorsDto>('/multichat/operators', { params: { realm } })
    .then(adapter.getAll);
};

const update = (
  operatorId: string,
  payload: { firstName: string; lastName: string; agencyTechName: string | null; realm: string },
) => {
  return Http.shared().instance.put(`/multichat/operators/${operatorId}`, payload);
};

const activate = (operatorId: string, payload: ActivateOperatorPayload) =>
  Http.shared().instance.put<{ Success: boolean }>(`/multichat/operators/${operatorId}/activate`, payload);

export const operatorRequest = {
  getAll,
  update,
  activate,
};

import React, { useCallback, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import debounce from 'lodash/debounce';

import { Layout } from 'src/components/App/views/Layout';
import { useOperators } from 'src/services/operator/hooks';
import { useAgencies } from 'src/services/agency/hooks';
import { OperatorAgencyMappingFormFields } from 'src/types/multichat/operator-agency-mapping';
import { transformDbIdToFrontId } from 'src/utils/platform/convert-id';
import { MultichatAgencyOperatorsMappingTable } from './Table';
import { MultichatAgencyOperatorsMappingFillingForm } from './FillingForm';
import * as S from './styled';

export const MultichatAgencyOperatorsMappingScreen = () => {
  const [searchedPhrase, setSearchedPhrase] = useState('');

  const search = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => setSearchedPhrase(e.target.value.toLowerCase()), 500),
    [],
  );

  const { operators, loading: operatorsLoading, updateOperator } = useOperators();
  const agenciesState = useAgencies();

  const [fillingModeEnabled, setFillingModeEnabled] = useState(false);

  const startOperatorFillingProcess = useCallback(() => {
    setFillingModeEnabled(true);
  }, []);

  const finishOperatorFillingProcess = useCallback(() => {
    setFillingModeEnabled(false);
  }, []);

  const onCancelOperatorFillingProcess = useCallback(() => {
    finishOperatorFillingProcess();
  }, [finishOperatorFillingProcess]);

  const onSubmitOperatorFillingProcess = useCallback(
    (values: OperatorAgencyMappingFormFields) => {
      const { firstName, lastName, agencyTechName, isReadyForExplicit, scheduleType } = values;
      const payload = { firstName, lastName, agencyTechName, isReadyForExplicit, scheduleType };
      updateOperator(transformDbIdToFrontId(values.operatorDbId), payload).then(() => finishOperatorFillingProcess());
    },
    [finishOperatorFillingProcess, updateOperator],
  );

  const filteredOperators = useMemo(() => {
    if (!searchedPhrase) {
      return operators;
    }

    return operators.filter(
      (op) =>
        op.operatorId.includes(searchedPhrase) ||
        op.operatorDbId.includes(searchedPhrase) ||
        op.firstName.toLowerCase().includes(searchedPhrase) ||
        op.lastName.toLowerCase().includes(searchedPhrase),
    );
  }, [operators, searchedPhrase]);

  return (
    <Layout containerSize="lg">
      <Typography component="h1" variant="h5">
        Agencies-Operators mapping
      </Typography>
      <S.Header>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-search"
              label="Search by operator (id/first name/last name)"
              type="search"
              variant="outlined"
              size="small"
              onChange={search}
            />
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" color="primary" fullWidth onClick={startOperatorFillingProcess}>
              Add
            </Button>
          </Grid>
        </Grid>
        {fillingModeEnabled && <S.HeaderOverlay />}
      </S.Header>
      <div>
        {fillingModeEnabled && agenciesState.value && (
          <MultichatAgencyOperatorsMappingFillingForm
            agencies={agenciesState.value}
            onSubmit={onSubmitOperatorFillingProcess}
            onCancel={onCancelOperatorFillingProcess}
          />
        )}
        {filteredOperators && agenciesState.value && !fillingModeEnabled && (
          <MultichatAgencyOperatorsMappingTable
            loading={operatorsLoading}
            operators={filteredOperators}
            agencies={agenciesState.value}
            onSave={updateOperator}
          />
        )}
      </div>
    </Layout>
  );
};

import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import { LoginScreen } from 'src/components/Auth/screens/Login';
import { useAuth } from 'src/services/auth';
import { HomeScreen } from 'src/components/App/screens/Home';
import {
  ReportByActivityScreen,
  ReportByBonusesScreen,
  ReportByCommissionsScreen,
  ReportByKPIScreen,
  ReportByMessagesScreen,
} from 'src/components/Report';

import { MultichatLifeStoriesScreen } from 'src/components/Multichat/LifeStories';
import { MultichatAgencyOperatorsMappingScreen } from 'src/components/Multichat/AgencyOperatorsMapping';
import { MultichatAgencyAnimatorsMappingScreen } from 'src/components/Multichat/AgencyAnimatorsMapping';
import { OperatorsManagingScreen } from 'src/components/Multichat/OperatorsManaging';
import { AnimatorsActivatorScreen } from 'src/components/Multichat/AnimatorsActivator';
import { Reports } from 'src/types/report/common';
import { Product } from 'src/types/product';
import { Datadog } from 'src/infrastructure/loggers/datadog/datadog';

import { Head } from 'src/components/common/head';
import { VerificationScreen } from './Verification/screens/Verification';
import { PrivateRoute } from './Auth/views/PrivateRoute';
import { UserRoles } from '../types/user/roles';
import { StartModerationProcessScreen } from './Moderation/screens/StartModerationProcess';
import { UserModerationScreen } from './Moderation/screens/UserModeration';
import { UserModerationFlureScreen } from './Moderation/screens/UserModeration/flure';
import { AdminUserModerationScreen } from './Moderation/screens/AdminUserModeration';
import { AdminUserContentModerationScreen } from './Moderation/screens/AdminUserContentModeration';
import { AdminUserContentModerationFlureScreen } from './Moderation/screens/AdminUserContentModeration/flure';
import { DailyBuzzScreen } from './DailyBuzz';
import { CategoriesScreen } from './DailyBuzz/categories';
import { AnimatorsScreen } from './Animators/preload-photos';
import { ProfileSettingsScreen } from './Animators/profile';
import { UserModerationRAWScreen } from './Moderation/screens/UserModeration/raw';
import { StartRAWModerationProcessScreen } from './Moderation/screens/StartModerationProcess/raw';
import { WaitingListScreen } from './WaitingList';
import { FlureChatModerationScreen } from './FlureChatModeration';
import { FlureFeedScreen } from './FlureFeed';
import { ChatPreviewScreen, EditMessageScreen, NewMessageScreen } from './FlureChat/screens';
import { AdminUserModerationRAWScreen } from './Moderation/screens/AdminUserModeration/raw';
import { AdminUserContentModerationRAWScreen } from './Moderation/screens/AdminUserContentModeration/raw';
import { AppProviders } from './app-providers';
import { AutoModerationRAWScreen } from './Moderation/screens/AutoModeration';
import { FlureChatModerationWordsListScreen } from './FlureChatModerationWordsList';
import { LocalEventsScreen } from './LocalEvents/screens/LocalEvents';
import { VibesAndPremiumManagementScreen } from './VibesAndPremiumManagement';
import { VerificationHistoryFlureScreen } from './Verification/screens/VerificationHistoryFlure';
import { OperatorsAndAgenciesManagingScreen } from './Multichat/operators-and-agencies-managing';

const AppRouter = () => {
  const { me, bootstrap, loading } = useAuth();

  useEffect(() => {
    (async () => {
      await bootstrap();
    })();
  }, [bootstrap]);

  const roles = useMemo(() => me?.roles || [], [me]);

  const lovingaProduct = useMemo(() => me?.realm === Product.Lovinga, [me]);
  const udatesProduct = useMemo(() => me?.realm === Product.Once, [me]);
  const flureProduct = useMemo(() => me?.realm === Product.Flure, [me]);
  const magnetProduct = useMemo(() => me?.realm === Product.Magnet, [me]);
  const rawProduct = useMemo(() => me?.realm === Product.RAW, [me]);

  const isAgencyAdministrator = useMemo(() => roles.includes(UserRoles.AgencyAdministrator), [roles]);
  const isAdministrator = useMemo(() => roles.includes(UserRoles.Supervisor), [roles]);
  const isOperator = useMemo(() => roles.includes(UserRoles.UsersOperator), [roles]);
  const isCustomerSupport = useMemo(() => roles.includes(UserRoles.CustomerSupport), [roles]);
  const isAgency = useMemo(() => roles.includes(UserRoles.Agency), [roles]);
  const isChatOperator = useMemo(() => roles.includes(UserRoles.ChatOperator), [roles]);
  const isPromoCodesOperator = useMemo(() => roles.includes(UserRoles.PromoCodesOperator), [roles]);

  const photoVerificationEnabled = useMemo(
    () => (lovingaProduct || flureProduct || magnetProduct) && (isOperator || isAdministrator),
    [isAdministrator, isOperator, lovingaProduct, flureProduct, magnetProduct],
  );
  const verificationHistoryEnabled = useMemo(
    () => flureProduct && (isOperator || isAdministrator),
    [isAdministrator, isOperator, flureProduct],
  );
  const userModerationEnabled = useMemo(
    () => lovingaProduct || udatesProduct || magnetProduct || flureProduct || rawProduct,
    [lovingaProduct, magnetProduct, udatesProduct, flureProduct, rawProduct],
  );
  const reportByBonusesSection = isAgencyAdministrator || (isAgency && magnetProduct);
  const reportByKPISection = udatesProduct;
  const reportByActivitySection = udatesProduct || magnetProduct;
  const flureChatSection = flureProduct && isChatOperator;
  const flureFeedSection = flureProduct && (isOperator || isAdministrator);
  const ModerationScreen = useMemo(() => {
    if (rawProduct) {
      return UserModerationRAWScreen;
    }

    if (flureProduct) {
      return UserModerationFlureScreen;
    }

    return UserModerationScreen;
  }, [flureProduct, rawProduct]);

  const AdminContentModerationScreen = useMemo(() => {
    if (rawProduct) {
      return AdminUserContentModerationRAWScreen;
    }

    if (flureProduct) {
      return AdminUserContentModerationFlureScreen;
    }

    return AdminUserContentModerationScreen;
  }, [flureProduct, rawProduct]);

  if (rawProduct || flureProduct) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('./index.css');
  }

  if (loading) {
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/" exact component={HomeScreen} />

        <PrivateRoute path={`/report-by-${Reports.commissions}`} component={ReportByCommissionsScreen} />
        <PrivateRoute path={`/report-by-${Reports.messages}`} component={ReportByMessagesScreen} />
        <PrivateRoute
          path={`/report-by-${Reports.bonuses}`}
          component={ReportByBonusesScreen}
          condition={reportByBonusesSection}
        />
        <PrivateRoute path={`/report-by-${Reports.kpi}`} component={ReportByKPIScreen} condition={reportByKPISection} />
        <PrivateRoute
          path={`/report-by-${Reports.activity}`}
          component={ReportByActivityScreen}
          condition={reportByActivitySection}
        />

        <PrivateRoute
          path="/local-events"
          component={LocalEventsScreen}
          condition={flureProduct && isPromoCodesOperator}
        />
        <PrivateRoute
          path="/vibes-and-premium-management"
          component={VibesAndPremiumManagementScreen}
          condition={flureProduct && isAdministrator}
        />
        <PrivateRoute path="/verification" component={VerificationScreen} condition={photoVerificationEnabled} />
        <PrivateRoute path="/verification-history" component={VerificationHistoryFlureScreen} condition={verificationHistoryEnabled} />

        <PrivateRoute
          path="/moderation"
          exact
          component={rawProduct ? StartRAWModerationProcessScreen : StartModerationProcessScreen}
          condition={userModerationEnabled && (isOperator || isAdministrator || isCustomerSupport)}
        />
        <PrivateRoute
          path="/moderation/content"
          component={ModerationScreen}
          condition={userModerationEnabled && (isOperator || isAdministrator)}
        />
        {rawProduct && (
          <PrivateRoute
            path="/moderation/auto"
            component={AutoModerationRAWScreen}
            condition={userModerationEnabled && (isOperator || isAdministrator)}
          />
        )}
        <PrivateRoute
          path="/moderation/user/:userId"
          exact
          component={rawProduct ? AdminUserModerationRAWScreen : AdminUserModerationScreen}
          condition={userModerationEnabled && (isAdministrator || isCustomerSupport)}
        />
        <PrivateRoute
          path="/moderation/user/:userId/:baseName/:mediaType"
          exact
          component={AdminContentModerationScreen}
          condition={userModerationEnabled && isAdministrator}
        />

        <PrivateRoute
          path="/multichat/life-stories"
          component={MultichatLifeStoriesScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/agencies-operators-mapping"
          component={MultichatAgencyOperatorsMappingScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/agencies-animators-mapping"
          component={MultichatAgencyAnimatorsMappingScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/animators-activator"
          component={AnimatorsActivatorScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/operators-managing"
          component={OperatorsManagingScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/operators-and-agencies-managing"
          component={OperatorsAndAgenciesManagingScreen}
          condition={isAgencyAdministrator}
        />

        <PrivateRoute
          path="/dailybuzz/questions"
          component={DailyBuzzScreen}
          condition={magnetProduct && isAdministrator}
        />
        <PrivateRoute
          path="/dailybuzz/categories"
          component={CategoriesScreen}
          condition={magnetProduct && isAdministrator}
        />

        <PrivateRoute
          path="/animators/preload-photos"
          component={AnimatorsScreen}
          condition={magnetProduct && isAdministrator}
        />
        <PrivateRoute
          path="/animators/profile"
          component={ProfileSettingsScreen}
          condition={magnetProduct && isAdministrator}
        />

        <PrivateRoute
          path="/waiting-list"
          component={WaitingListScreen}
          condition={flureProduct && (isOperator || isAdministrator)}
        />
        <PrivateRoute
          path="/flure-chat-moderation"
          component={FlureChatModerationScreen}
          condition={flureProduct && isChatOperator}
        />
        <PrivateRoute
          path="/flure-chat-moderation-words"
          component={FlureChatModerationWordsListScreen}
          condition={flureProduct && isChatOperator}
        />

        <PrivateRoute path="/flure-chat" exact component={ChatPreviewScreen} condition={flureChatSection} />
        <PrivateRoute path="/flure-chat/new" exact component={NewMessageScreen} condition={flureChatSection} />
        <PrivateRoute path="/flure-chat/edit/:messageId" exact component={EditMessageScreen} condition={flureProduct} />
        <PrivateRoute path="/flure-feed" exact component={FlureFeedScreen} condition={flureFeedSection} />

        <Route path="/auth" render={() => (!me?.token ? <LoginScreen /> : <Redirect to="/" />)} />
      </Switch>
    </BrowserRouter>
  );
};

export const App = () => {
  useEffect(() => {
    Datadog.init();
  }, []);

  return (
    <AppProviders>
      <Head />
      <AppRouter />
    </AppProviders>
  );
};

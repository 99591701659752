import React, { useCallback } from 'react';
import generator from 'generate-password';
import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

import { Gender, UserDto } from 'src/types/user';
import {
  operatorRequest,
  authRequest,
  userRequest,
  UpdateUserPayload,
  UpdateUserLocationPayload,
  IdentityCredentials,
} from 'src/network';
import { getLocation, locationHasError } from 'src/network/location';
import { Product } from 'src/types/product';
import { getRealm } from 'src/utils/realm';
import { ActivateOperatorPayload } from 'src/network/operator/types';
import { useAuth } from 'src/services/auth';
import { OperatorBonusCommission, OperatorCities, OperatorCommission } from 'src/types/operator';

import { operatorLocations, DEFAULT_LANGUAGE } from '../config';

export type CreateOperatorPayload = {
  firstName: string;
  lastName: string;
  commission: OperatorCommission;
  starterBonusCommission?: OperatorBonusCommission;
  followBonusCommission?: OperatorBonusCommission;
  isReadyForExplicit: boolean;
  city: OperatorCities;
  isFreeMember?: boolean;
  freeMemberAnimatorClientId?: string;
  animatorCity?: string;
  animatorCountry?: string;
  agencyTechName?: string;
};

export const useCreateOperator = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { me } = useAuth();

  const createOperator = useCallback(
    async (payload: CreateOperatorPayload) => {
      const isMagnetProduct = me?.realm === Product.Magnet;
      const isOnceProduct = me?.realm === Product.Once;

      try {
        const { country, latitude, longitude, language } = operatorLocations[payload.city];

        let location: UpdateUserLocationPayload = {
          country,
          latitude,
          longitude,
          city: payload.city,
          languages: [language],
        };

        if (payload.isFreeMember) {
          const animatorData: Partial<UserDto> & { error?: string } = await userRequest
            .getById(payload.freeMemberAnimatorClientId!, true)
            .catch(() => ({ error: 'Wrong Animator ID' }));

          if (animatorData.error) {
            throw new Error(animatorData.error);
          }

          const locationData = await getLocation({
            city: payload.animatorCity!,
            country: payload.animatorCountry!,
          });

          if (locationHasError(locationData)) {
            throw new Error(
              `Wrong location data for city: ${payload.animatorCity}, country: ${payload.animatorCountry}. Error status: ${locationData.error}`,
            );
          }

          location = {
            city: payload.animatorCity,
            country: payload.animatorCountry,
            latitude: locationData.lat,
            longitude: locationData.lng,
            languages: [DEFAULT_LANGUAGE],
          };
        }

        const registerData: IdentityCredentials = {
          email: `atolive1+${nanoid(6)}@gmail.com`,
          password: generator.generate({
            length: 10,
            numbers: true,
            symbols: true,
          }),
        };
        const { data: identity } = await authRequest.register(registerData);

        const updateUserPayload: UpdateUserPayload = {
          name: `${payload.firstName}_${payload.lastName}`,
          birthday: '1995-04-01T00:00:00.000Z', // mocked birthday. should be just more than 18 years old
          city: location.city,
          gender: Gender.Male,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
          languages: isOnceProduct ? location.languages : undefined,
        };

        if (isOnceProduct) {
          updateUserPayload.languages = location.languages;
        }

        await userRequest.update(identity.id, updateUserPayload);

        const activateOperatorPayload: ActivateOperatorPayload = {
          firstName: payload.firstName,
          realm: getRealm(me?.realm),
          lastName: payload.lastName,
          commission: payload.commission,
          isReadyForExplicit: payload.isReadyForExplicit,
          city: location.city,
          country: location.country,
          isFreeMember: payload.isFreeMember,
          freeMemberAnimatorClientId: payload.freeMemberAnimatorClientId || undefined,
          agencyTechName: payload.agencyTechName,
        };

        if (isMagnetProduct) {
          activateOperatorPayload.bonuses = [
            {
              bonus: 'starter_bonus',
              value: payload.starterBonusCommission!,
            },
            {
              bonus: 'follow_bonus',
              value: payload.followBonusCommission!,
            },
          ];
        }

        await operatorRequest.activate(identity.id, activateOperatorPayload);

        enqueueSnackbar(
          `Operator successfully created \n id: ${identity.id} \n email: ${registerData.email}  \n password: ${registerData.password}`,
          {
            variant: 'success',
            persist: true,
            style: { whiteSpace: 'pre-line' },
            action: () => (
              <Button style={{ color: 'white' }} onClick={() => closeSnackbar()}>
                Close
              </Button>
            ),
          },
        );
        return true;
      } catch (error) {
        enqueueSnackbar((error as Error)?.message || 'Bad request', { variant: 'error' });

        return false;
      }
    },
    [closeSnackbar, enqueueSnackbar, me?.realm],
  );

  return createOperator;
};

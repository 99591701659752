import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import ReactHookFormSelect from 'src/components/common/ReactHookFormSelect';
import { useCreateOperator } from 'src/services/operator/hooks';

import { operatorLocations, operatorCities, validCommissions } from 'src/services/operator/config';
import { validFollowBonusCommissions, validStarterBonusCommissions } from './config';
import { validationSchema, defaultValues } from './schema';
import * as S from './styled';

const renderCommissionItems = (commissions: number[]) =>
  commissions.map((commission) => (
    <MenuItem key={commission} value={commission}>
      {commission}
    </MenuItem>
  ));

export const OperatorCreationForm = () => {
  const createOperator = useCreateOperator();

  const { me } = useAuth();
  const isMagnetProduct = me?.realm === Product.Magnet;

  const { control, handleSubmit, errors, formState, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async (values) =>
    createOperator(values)
      .then(() => reset())
      // we use empty catch to not use the fact it happened
      .catch(),
  );

  const commissionItems = useMemo(() => renderCommissionItems(validCommissions), []);
  const starterBonusCommissionItems = useMemo(() => renderCommissionItems(validStarterBonusCommissions), []);
  const validFollowBonusCommissionItems = useMemo(() => renderCommissionItems(validFollowBonusCommissions), []);
  const cityItems = useMemo(
    () =>
      operatorCities.map((city) => (
        <MenuItem key={city} value={city}>
          {operatorLocations[city].country} - {city}
        </MenuItem>
      )),
    [],
  );

  return (
    <S.Form onSubmit={handleFormSubmit}>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="firstName"
          type="string"
          control={control}
          label="Operator first name"
          error={!!errors.firstName}
          variant="outlined"
          size="small"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="lastName"
          type="string"
          control={control}
          label="Operator last name"
          error={!!errors.lastName}
          variant="outlined"
          size="small"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <ReactHookFormSelect
          size="small"
          error={!!errors.commission}
          name="commission"
          label="Select commission"
          control={control}
          variant="outlined"
        >
          {commissionItems}
        </ReactHookFormSelect>
      </S.InputWrapper>
      {isMagnetProduct && (
        <>
          <S.InputWrapper>
            <ReactHookFormSelect
              size="small"
              error={!!errors.starterBonusCommission}
              name="starterBonusCommission"
              label="Select starter bonus commission"
              control={control}
              variant="outlined"
            >
              {starterBonusCommissionItems}
            </ReactHookFormSelect>
          </S.InputWrapper>
          <S.InputWrapper>
            <ReactHookFormSelect
              size="small"
              error={!!errors.followBonusCommission}
              name="followBonusCommission"
              label="Select follow bonus commission"
              control={control}
              variant="outlined"
            >
              {validFollowBonusCommissionItems}
            </ReactHookFormSelect>
          </S.InputWrapper>
        </>
      )}
      <S.InputWrapper>
        <Controller
          name="isReadyForExplicit"
          control={control}
          error={!!errors.isReadyForExplicit}
          render={({ onChange, value, ...rest }) => (
            <FormControlLabel
              control={<Checkbox {...rest} checked={value} onChange={(e) => onChange(e.target.checked)} />}
              label="is ready for explicit"
            />
          )}
        />
      </S.InputWrapper>

      <S.InputWrapper>
        <ReactHookFormSelect
          error={!!errors.city}
          size="small"
          name="city"
          label="Select City"
          control={control}
          variant="outlined"
        >
          {cityItems}
        </ReactHookFormSelect>
      </S.InputWrapper>

      <Button type="submit" variant="contained" color="primary" disabled={formState.isSubmitting}>
        Create
      </Button>
    </S.Form>
  );
};

import React from 'react';

import { FormAction, FormType } from '../../types';

import { CreateOperatorForm } from './forms/create-operator';

type Props = {
  type: FormType;
  action: FormAction;
};

type ManagingType = `${FormType}_${FormAction}`;

const format = (type: FormType, action: FormAction): ManagingType => `${type}_${action}`;

export const ManagingForm = (props: Props) => {
  const { type, action } = props;

  switch (format(type, action)) {
    case format(FormType.Operator, FormAction.Create):
      return <CreateOperatorForm />;

    default:
      return null;
  }
};

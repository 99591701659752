import React, { useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';
import { useAgencies } from 'src/services/agency/hooks';
import { useAnimators } from 'src/services/animator/hooks';
import { AgenciesAnimatorsListItem, MultichatAgencyAnimatorsMappingTable } from './Table';
import { AgencyAnimatorsFillingForm } from './FillingForm';
import * as S from './styled';

export const MultichatAgencyAnimatorsMappingScreen = () => {
  const { animatorsState, linkOperatorWithAgency } = useAnimators();
  const { value: animators, loading: animatorsLoading } = animatorsState;
  const { value: agencies, loading: agenciesLoading } = useAgencies();

  const dataLoading = animatorsLoading || agenciesLoading;

  const [editableAgencyTechName, setEditableAgencyTechName] = useState<string | null>(null);

  const editableAgency = useMemo(() => {
    if (!editableAgencyTechName) {
      return null;
    }

    const foundAgency = agencies?.find((agency) => agency.techName === editableAgencyTechName);

    return foundAgency;
  }, [agencies, editableAgencyTechName]);

  const agenciesAnimatorsList = useMemo<AgenciesAnimatorsListItem[]>(() => {
    if (!agencies || !animators) {
      return [];
    }

    const linkedAnimatorsCounts = animators.reduce((counts, animator) => {
      if (animator.agencyTechName) {
        const currentCount = counts[animator.agencyTechName] || 0;

        // eslint-disable-next-line no-param-reassign
        counts[animator.agencyTechName] = currentCount + 1;
      }

      return counts;
    }, {} as Record<string, number>);

    return agencies.map((agency) => ({
      name: agency.name,
      techName: agency.techName,
      animatorsCount: linkedAnimatorsCounts[agency.techName] || 0,
    }));
  }, [agencies, animators]);

  const onEdit = (agencyTechName: string) => {
    setEditableAgencyTechName(agencyTechName);
  };

  const onClose = () => {
    setEditableAgencyTechName(null);
  };

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Agencies-Animators mapping
      </Typography>
      <S.Content>
        {editableAgency ? (
          <AgencyAnimatorsFillingForm
            agency={editableAgency}
            animators={animators!}
            onClose={onClose}
            onLinkAnimator={linkOperatorWithAgency}
          />
        ) : (
          <MultichatAgencyAnimatorsMappingTable loading={dataLoading} list={agenciesAnimatorsList} onChange={onEdit} />
        )}
      </S.Content>
    </Layout>
  );
};

import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useSnackbar } from 'notistack';

import { agencyRequest } from 'src/network/agency';
import { UserRoles } from 'src/types/user/roles';
import { getRealm } from 'src/utils/realm';
import { useAuth } from '../auth';

export const useAgencies = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { me } = useAuth();
  const [agenciesState, fetchAgencies] = useAsyncFn(
    async () => {
      const result = await agencyRequest.getAll(getRealm(me?.realm));
      return result;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    if (agenciesState.error) {
      enqueueSnackbar('Failed to load agencies: ' + agenciesState.error.message, { variant: 'error' });
    }
  }, [agenciesState.error, enqueueSnackbar]);

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  return agenciesState;
};

export const useAgencyTechName = () => {
  const { me } = useAuth();

  const [agencyTechNameState, fetchAgencyTechName] = useAsyncFn(
    async () => {
      if (!me) {
        return undefined;
      }

      if (!me.roles.includes(UserRoles.Agency)) {
        return undefined;
      }

      const result = await agencyRequest.getAgencyName(me.id);
      return result;
    },
    [me?.id],
    { loading: true },
  );

  useEffect(() => {
    fetchAgencyTechName().then();
  }, [fetchAgencyTechName]);

  return agencyTechNameState;
};
